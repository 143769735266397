import { MONTH_NAMES } from "../constants";
import { Coordinates } from "../types/line-data";

export function getDataLine(
    label: string,
    backgroundColor: Array<string | CanvasPattern>,
    borderColor: string,
    data: Array<Coordinates>,
    yAxisID: string,
    pointHoverRadius: number = 10,
    fill: boolean = false,
) {
    return {
        label,
        backgroundColor,
        borderColor,
        fill,
        data,
        yAxisID,
        pointHoverRadius,
    };
}

export function formatXAxisLabelDate(date: string): string {
    const LocaleDateString = new Date(Date.parse(date)).toLocaleDateString();
    const dateParts = LocaleDateString.split("/");
    return `${MONTH_NAMES[parseInt(dateParts[0], 10) - 1]} ${dateParts[1]}, ${dateParts[2]}`;
}

export function combineNameAndId(playerName: string, playerId: number) {
    return `${playerName.replace(" ", "_").toLocaleLowerCase()}_${playerId}`;
}

export function formatSimDataLineLabel(playerName: string, playerId: number, rater: string, rating: number | null = null) {
    const maybeRating = rating ? `_${rating}` : "";
    return `${rater}${maybeRating}_${combineNameAndId(playerName, playerId)}`;
}

export function appendRapidOffSettingLabel(label: string, rapid: boolean) {
    if (!rapid) {
        return `${label}_roff`;
    }

    return label;
}

/**
 *   Primary player A is the player page you're visting (playerId is their id)
 *
 * - primaryNewSim means you're generating new start ratings for primary player A.
 *
 *   see new start rating dialog
 *
 */
export function primaryNewSimLabel(
    rater: string,
    rating: number | null,
    rapid: boolean,
) {
    const label = `${rater}_${rating || ""}`;
    return appendRapidOffSettingLabel(label, rapid);
}

/**
 *   Primary player A is the player page you're visting (playerId is their id)
 *
 * - secondaryNewSim means you're generating new start ratings for another player on player A's page
 *
 *   see new start rating dialog
 *
 */
export function secondaryNewSimLabel(
    rater: string,
    rating: number | null,
    playerId: number,
    playerName: string,
    rapid: boolean,
) {
    const label = formatSimDataLineLabel(
        playerName,
        playerId,
        rater,
        rating,
    );

    return appendRapidOffSettingLabel(label, rapid);
}

/**
 *   Primary player A is the player page you're visting (playerId is their id)
 *
 * - primaryNewSimSecondaryRaw means you're generating new start ratings for primary player A
 *   and you're including other players from their simulation run.
 *
 *   see new start rating dialog
 *
 */
export function primaryNewSimSecondaryRawSim(
    rater: string,
    playerId: number,
    playerName: string,
    primaryRating: number,
    rapid: boolean,
) {
    const label = `${rater}_${primaryRating}<--${combineNameAndId(playerName, playerId)}`;
    return appendRapidOffSettingLabel(label, rapid);
}

/**
 *   Primary player A is the player page you're visting (playerId is their id)
 *
 * - secondaryNewSimSecondaryRaw means you're generating new start ratings for another
 *   player on player A's page and you're including other players from their simulation run.
 *
 *   see new start rating dialog
 *
 */
export function secondaryNewSimSecondaryRawSim(
    rater: string,
    rating: number | null,
    playerId: number,
    playerName: string,
    rapid: boolean,
    secondaryNewName: string,
) {
    const label = `${formatSimDataLineLabel(
        secondaryNewName,
        playerId,
        rater,
        rating,
    )}<--${combineNameAndId(playerName, playerId)}`;
    return appendRapidOffSettingLabel(label, rapid);
}

export function formatTenniscoresDataLineLabel(playerName: string, playerId: number) {
    return `ts_${playerName.replace(" ", "_").toLocaleLowerCase()}_${playerId}`;
}
