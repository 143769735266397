import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/system/Box";
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridFilterModel,
    GridRowHeightParams,
    GridRowsProp,
    GridToolbar,
} from "@mui/x-data-grid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAptaDataContext } from "../../hooks/AptaDataProvider";
import { useAuthContext } from "../../hooks/AuthProvider";
import { formatAccordianDate } from "../../utils/stats";
import MainAppBar from "../AppBar/MainAppBar";
import MainBreadCrumb from "../BreadCrumb/MainBreadCrumb";
import Footer from "../Footer/Footer";

/**
 * Currently serves as the "Home" page
 */
export default function PlayerDirectory(): JSX.Element {
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const { directory } = useAptaDataContext();
    const { refreshToken, accessToken } = authContext;
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [loading, setLoading] = React.useState(false);
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);

    const handleSnackBarClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    React.useEffect(() => {
        if (!(directory && directory.length > 0)) {
            setLoading(true);
            return;
        }

        const formattedRows = directory.map((row, index: number) => ({
            id: index + 1,
            playerNameLink: (
                <a
                    href={`/players/${row.player_id}?accessToken=${accessToken}&refreshToken=${refreshToken}`}
                >
                    {row.player_name}
                </a>
            ),
            playerName: row.player_name,
            playerId: row.player_id,
            lastMatchDate: new Date(row.last_match_date),
            leagues: row.league_names,
        }));

        setRows(formattedRows);
        setLoading(false);
    }, [directory, accessToken, refreshToken]);

    const columns: GridColDef[] = [
        {
            field: "playerName",
            headerName: "Name",
            width: 200,
            renderCell: (entry) => {
                const row = entry.row as { playerNameLink: string };
                return (
                    <span
                        style={{
                            cursor: "pointer",
                            color: "#1976d2",
                            textDecoration: "underline",
                        }}
                    >
                        {row.playerNameLink}
                    </span>
                );
            },
        },
        {
            field: "playerId",
            headerName: "Id",
            width: 100,
            renderCell: (entry) => {
                const row = entry.row as { playerId: number };
                return (
                    <>
                        <span>{row.playerId}</span>
                        <ContentCopyIcon
                            fontSize="small"
                            onClick={async () => {
                                await navigator.clipboard.writeText(row.playerId.toString());
                                setSnackBarOpen(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            field: "leagues",
            headerName: "Leagues",
            width: 350,
            renderCell: (entry) => {
                const row = entry.row as { leagues?: string };
                const leagues: string = row.leagues ?? "";
                const sortedLeagues = leagues.split(",").sort();
                return (
                    <div>
                        {sortedLeagues.map((league) => (
                            <span key={league}>
                                {league}
                                <br />
                            </span>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "lastMatchDate",
            headerName: "Last Match Date",
            width: 200,
            type: "datetime",
            filterable: false,
            renderCell: (entry) => {
                const row = entry.row as { lastMatchDate: string };
                return formatAccordianDate(row.lastMatchDate);
            },
        },
    ];

    if (loading) {
        return (
            <>
                <MainAppBar />
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        height: "50vh",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            </>
        );
    }

    return (
        <>
            <MainAppBar />
            <MainBreadCrumb path="/" />
            <Box component="div" position="relative">
                <Snackbar
                    autoHideDuration={2000}
                    open={snackBarOpen}
                    onClose={handleSnackBarClose}
                    message="playerId copied"
                    sx={{
                        maxWidth: "200px",
                    }}
                />
            </Box>
            <Box sx={{ width: "100%", minHeight: "800px" }} component="div">
                <DataGrid
                    autoHeight
                    columns={columns}
                    filterModel={filterModel}
                    getRowHeight={(params: GridRowHeightParams) => {
                        const rowHeight = 52;
                        const model = params.model as { leagues?: string };
                        const leagues: string = model.leagues ?? "";
                        return leagues.split(",").length > 1
                            ? rowHeight + 15 * leagues.split(",").length
                            : rowHeight;
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "lastMatchDate", sort: "desc" }],
                        },
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                    onCellClick={(params: GridCellParams) => {
                        const row = params.row as { playerId?: number; playerName: string };
                        if (params.field !== "playerId") {
                            navigate(`/players/${row.playerId}`, {
                                state: { playerId: row.playerId, playerName: row.playerName },
                            });
                        }
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    rows={rows}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: { printOptions: { disableToolbarButton: true } },
                        filterPanel: {
                            sx: {
                                maxWidth: "95vw",
                                ".MuiDataGrid-filterFormColumnInput": { width: "100px" },
                                ".MuiDataGrid-filterFormValueInput": { width: "150px" },
                            },
                        },
                        panel: {
                            sx: {
                                top: "-65px !important",
                            },
                        },
                    }}
                    sortingOrder={["asc", "desc"]}
                    sx={{
                        "&.MuiDataGrid-root": {
                            border: "none",
                        },
                        p: 0.5,
                    }}
                    data-testid="PlayerDirectory_datagrid"
                />
            </Box>
            <Footer />
        </>
    );
}
