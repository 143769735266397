import { DirectoryEntry } from "@apta/data/dist/directory";
import { Division } from "@apta/data/dist/divisions";
import { League } from "@apta/data/dist/leagues";
import { Season } from "@apta/data/dist/seasons";
import { TournamentLabel } from "@apta/data/dist/tournament";
import React, { createContext, useContext } from "react";
import { config } from "../config";
import { useAuthContext } from "./AuthProvider";

export interface AptaData {
    directory: DirectoryEntry[];
    divisions: Division[];
    leagues: League[];
    seasons: Season[];
    tournamentLabels: TournamentLabel[];
}

const AptaDataContext = createContext<AptaData>({
    directory: [],
    divisions: [],
    leagues: [],
    seasons: [],
    tournamentLabels: [],
});

export function useAptaDataContext() {
    return useContext(AptaDataContext);
}

export function AptaDataProvider({
    children,
}: {
    children: React.ReactElement | React.ReactElement[];
}) {
    const { handleGetRequest } = useAuthContext();
    const [divisions, setDivisions] = React.useState<Division[]>([]);
    const [directory, setDirectory] = React.useState<DirectoryEntry[]>([]);
    const [leagues, setLeagues] = React.useState<League[]>([]);
    const [seasons, setSeasons] = React.useState<Season[]>([]);
    const [tournamentLabels, setTournamentLabels] = React.useState<
    TournamentLabel[]
    >([]);

    const fetchLeagues = React.useCallback(() => {
        handleGetRequest<League[]>(`${config.apiStandard}/api/leagues`)
            .then((data) => {
                setLeagues(data);
            })
            .catch(() => {});
    }, [handleGetRequest]);

    const fetchSeasons = React.useCallback(() => {
        handleGetRequest<Season[]>(`${config.apiStandard}/api/seasons`)
            .then((data) => {
                setSeasons(data);
            })
            .catch(() => {});
    }, [handleGetRequest]);

    const fetchTournamentLabels = React.useCallback(() => {
        handleGetRequest<TournamentLabel[]>(
            `${config.apiStandard}/api/tournaments/labels`,
        )
            .then((data) => {
                setTournamentLabels(data);
            })
            .catch(() => {});
    }, [handleGetRequest]);

    const fetchDivisions = React.useCallback(() => {
        handleGetRequest<Division[]>(`${config.apiStandard}/api/divisions`)
            .then((data) => {
                setDivisions(data);
            })
            .catch(() => {});
    }, [handleGetRequest]);

    const fetchPlayerDirectory = React.useCallback(() => {
        const headers: { [key: string]: string } = {
            Accept: "application/zip",
        };

        handleGetRequest<DirectoryEntry[]>(
            `${config.apiStandard}/api/directory`,
            headers,
        )
            .then((data) => setDirectory(data))
            .catch(() => {});
    }, [handleGetRequest]);

    React.useEffect(() => {
        fetchDivisions();
        fetchLeagues();
        fetchPlayerDirectory();
        fetchSeasons();
        fetchTournamentLabels();
    }, [
        fetchDivisions,
        fetchLeagues,
        fetchPlayerDirectory,
        fetchSeasons,
        fetchTournamentLabels,
    ]);

    const data = React.useMemo(
        () => ({
            directory,
            divisions,
            leagues,
            seasons,
            tournamentLabels,
        }),
        [directory, divisions, leagues, seasons, tournamentLabels],
    );

    return (
        <AptaDataContext.Provider value={data}>{children}</AptaDataContext.Provider>
    );
}
