import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import * as React from "react";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export default function ChartInfoDialog({ open, handleClose }: Props): JSX.Element {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Chart Info</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography>
                        Colored points are the end rating of each match.
                    </Typography>
                    <Typography>
                        White/black points are skipped matches.
                    </Typography>
                    <Typography>
                        You can click a rectangular rating label (for example pti_v2) to toggle a line on/off.
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
