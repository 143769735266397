import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import * as React from "react";

import Box from "@mui/material/Box";
import { config } from "../../config";
import { useAuthContext } from "../../hooks/AuthProvider";
import { getTokenPayload } from "../../utils/auth";

export default function FeedbackDialog(): JSX.Element {
    const theme = useTheme();
    const desktopMatch = useMediaQuery(theme.breakpoints.up("md"));
    const { accessToken, handlePostRequest } = useAuthContext();
    const { username } = getTokenPayload(accessToken);
    const [open, setOpen] = React.useState(false);
    const [responseOpen, setResponseOpen] = React.useState(false);
    const [userInput, setUserInput] = React.useState("");
    const [success, setSuccess] = React.useState(false);
    const [emptyMessage, setEmptyMessage] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setUserInput("");
        setEmptyMessage(false);
    };

    const handleResponseClose = () => {
        setResponseOpen(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value) {
            setEmptyMessage(false);
        }
        setUserInput(e.target.value?.trim());
    };

    const handleSubmit = () => {
        if (!userInput.length) {
            setEmptyMessage(true);
            return;
        }

        handlePostRequest<{ [text: string]: string }>(
            `${config.apiStandard}/api/feedback`,
            JSON.stringify({ text: userInput.trim(), username }),
            "application/json",
        )
            .then(() => {
                handleClose();
                setResponseOpen(true);
                setSuccess(true);
            })
            .catch(() => {
                handleClose();
                setResponseOpen(true);
                setSuccess(false);
            });
    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                sx={{
                    textTransform: "none",
                    color: "white",
                    borderColor: "white",
                    marginLeft: 0.5,
                    marginRight: 0.5,
                }}
            >
                Feedback
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ minWidth: desktopMatch ? 360 : 200 }}>Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ marginBottom: 1 }}>
                        Share your feedback with us!
                    </DialogContentText>
                    <TextField
                        autoFocus
                        fullWidth
                        id="filled-multiline-static"
                        label="Message"
                        multiline
                        rows={4}
                        defaultValue=""
                        variant="filled"
                        onChange={(e) => handleInputChange(e)}
                        error={emptyMessage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={responseOpen} onClose={handleResponseClose}>
                <DialogTitle sx={{ minWidth: desktopMatch ? 360 : 200 }}>Feedback</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleResponseClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "grey",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box sx={{ marginBottom: 1 }}>
                        <Stack direction="column" alignItems="center" spacing={2}>
                            {
                                success
                                    ? <CheckCircleOutlineIcon color="success" style={{ width: 50, height: 50 }} />
                                    : <InfoIcon color="error" style={{ width: 50, height: 50 }} />
                            }
                            {
                                success
                                    ? <Typography component="span" variant="h5">Thank You!</Typography>
                                    : <Typography component="span" variant="h5">Error</Typography>
                            }
                            {
                                success
                                    ? <Typography component="span">Your message has been sent.</Typography>
                                    : <Typography component="span">Your submission has failed, please try again.</Typography>
                            }
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
