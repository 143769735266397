import LogoutIcon from "@mui/icons-material/Logout";
import { Alert, AlertTitle } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { postResource, xWwwFormUrlEncode } from "../../service/request.service";
import { useAuthContext } from "../../hooks/AuthProvider";
import FeedbackDialog from "../Dialogs/FeedbackDialog";
import MaintenanceBanner from "../Banners/MaintenanceBanner";

export default function MainAppBar() {
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const [errors, setErrors] = React.useState<string[]>([]);
    const details: { [key: string]: string } = {
        token: authContext.refreshToken,
        client_id: config.oauth2.clientId,
    };
    const formBody = xWwwFormUrlEncode(details);
    const data = formBody.join("&");
    const url = `${config.oauth2.authorizationServerHost}/oauth2/revoke`;
    const logOut = () => {
        postResource(url, data, "application/x-www-form-urlencoded")
            .then(() => {
                authContext.destroySession();
                navigate("/", { replace: true });
            })
            .catch((error: Error) => {
                setErrors((currentErrors) => {
                    currentErrors.push(error.message);
                    return [...currentErrors];
                });
            });
    };

    return (
        <>
            <MaintenanceBanner />
            {
                errors.length > 0 && (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        <ul>
                            {errors.map((item) => (
                                <li key={item}>{item}</li>
                            ))}
                        </ul>
                    </Alert>
                )
            }
            <Box sx={{ mb: 1 }}>
                <AppBar
                    position="static"
                    sx={{
                        backgroundColor: "#004D40", boxShadow: "none", p: 1, pr: 0,
                    }}
                >
                    <Toolbar sx={{ justifyContent: "right" }}>
                        <FeedbackDialog />
                        <Button data-testid="appbar_logoutbutton" color="inherit" onClick={logOut}><LogoutIcon /></Button>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
}
