/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import pako from "pako";

export async function getResource<T>(url: string, token: string, headers: { [key: string]: string }): Promise<T> {
    if (token) {
        // eslint-disable-next-line no-param-reassign
        headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(url, {
        headers,
    });
    const contentType = response.headers.get("Content-Type");

    if (response.ok && contentType && contentType.includes("application/json")) {
        const json = await response.json();
        return json;
    }
    if (response.ok && contentType && contentType.includes("application/gzip")) {
        const arrayBuffer = await response.arrayBuffer();
        const decompressedData = pako.ungzip(new Uint8Array(arrayBuffer), { to: "string" });
        const jsonData = JSON.parse(decompressedData);
        return jsonData;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw Error("getResource network error");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function postResource<T>(url: string, data: any, contentType: string, token = ""): Promise<T> {
    const headers: { [key: string]: string } = {
        "Content-Type": contentType,
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(url, {
        method: "POST",
        cache: "no-cache",
        headers,
        body: data,
    });

    let json;

    // Control for empty response
    try {
        json = await response.json();

        if (response.ok) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return json;
        }
    } catch {
        if (response.ok) {
            return {} as T;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw Error(json);
}

export function xWwwFormUrlEncode(details: { [key: string]: string }): string[] {
    const formBody = [];

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in details) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    }

    return formBody;
}
