import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { useAuthContext } from "../../hooks/AuthProvider";
import MainAppBar from "../AppBar/MainAppBar";

interface Health {
    status: string;
    sqlite_modification_timestamp: string;
}

export default function HealthPage(): JSX.Element {
    const navigate = useNavigate();
    const authContext = useAuthContext();
    const { handleGetRequest } = authContext;

    const [apiHealth, setApiHealth] = React.useState<Health>();
    const [simApiHealth, setSimApiHealth] = React.useState<Health>();

    React.useEffect(() => {
        handleGetRequest<Health>(`${config.apiStandard}/api/health`)
            .then((resp) => setApiHealth(resp))
            .catch(() => { });

        handleGetRequest<Health>(`${config.apiSimulation}/api/simulation/health`)
            .then((resp) => setSimApiHealth(resp))
            .catch(() => { });
    }, [handleGetRequest]);

    return (
        <>
            <MainAppBar />
            <ArrowBackIosIcon
                fontSize="large"
                onClick={() => navigate(-1)}
                sx={{ ml: 3, mt: 3 }}
            />
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                <Box component="div">
                    <Typography variant="h5">System Status</Typography>
                    <Stack sx={{ mt: 5 }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="h6">Node API</Typography>
                            <CircleIcon color={apiHealth ? "success" : "error"}>status</CircleIcon>
                        </Stack>
                        <Typography>{`sqlite modified at ${(new Date(apiHealth?.sqlite_modification_timestamp || "")).toLocaleString()}`}</Typography>
                    </Stack>
                    <Stack sx={{ mt: 5 }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="h6">Python API</Typography>
                            <CircleIcon color={simApiHealth ? "success" : "error"}>status</CircleIcon>
                        </Stack>
                        <Typography>{`sqlite modified at ${(new Date(simApiHealth?.sqlite_modification_timestamp || "")).toLocaleString()}`}</Typography>
                    </Stack>
                </Box>
            </Box>
        </>
    );
}
