import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import React from "react";
import { Line } from "react-chartjs-2";
import { RATERS, TENNISCORES } from "../../constants";
import { PlayerProps } from "../../types/line-data";

export const OPTIONS = {
    stacked: false,
    interaction: {
        mode: "point",
        intersect: true,
    },
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: true,
            font: {
                size: 20,
                weight: "bold",
            },
        },
        legend: {
            display: true,
            labels: {
                font: {
                    size: 20,
                },
            },
        },
        tooltip: { // This is font for the hover effect over plotted points
            titleFont: {
                size: 20,
            },
            bodyFont: {
                size: 20,
            },
        },
    },
    legend: {
        position: "top",
        labels: {
            font: {
                size: "100em",
            },
        },
    },
    scales: {
        x: {
            title: {
                display: false,
                text: "Match Date",
                font: {
                    size: 20,
                    weight: "bold",
                },
            },
            ticks: {
                minRotation: 45,
                autoSkip: true,
                maxTicksLimit: 15,
                beginAtZero: true,
                stepSize: 1,
                font: {
                    size: 15,
                },
                source: "data",
                includeBounds: true,
            },
            grid: {
                display: false,
            },
            type: "timeseries", // https://www.chartjs.org/docs/latest/axes/cartesian/timeseries.html
            time: {
                unit: "day",
                displayFormats: {
                    day: "MMM dd, yyyy",
                },
                tooltipFormat: "M/dd/yyyy hh:mm aa",
            },
        },
        y1: {
            display: true,
            position: "right",
            title: {
                display: true,
                text: [RATERS, TENNISCORES],
                font: {
                    size: 20,
                    weight: "bold",
                },
            },
            ticks: {
                font: {
                    size: 15,
                },

            },
            reverse: true,
            type: "linear",
        },
    },
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    Legend,
);

export function LineChart({ dataSet }: PlayerProps): JSX.Element {
    return (
        <div
            className="chart-container"
            style={{
                position: "relative", margin: "auto", height: "40em",
            }}
        >
            <Line
                data={{ datasets: dataSet }}
                options={OPTIONS}
            />
        </div>
    );
}
