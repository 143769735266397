export interface Config {
    apiStandard: string;
    apiSimulation: string;
    oauth2: {
        authorizationServerHost: string;
        clientId: string;
        redirectUri: string;
    }
}

export type Env = "local" | "production";

export const APTA_API = "https://api.platformtennisindex.org";
export const APTA_WEB = "https://whatif.platformtennisindex.org";

export function getEnv(): Env {
    const { host } = window.location;

    if (host.includes("platformtennisindex")) {
        return "production";
    }

    return "local";
}

// ngrok is for local development
const ngrokCallback = "";

export const config: Config = {
    oauth2: {
        authorizationServerHost: "https://auth.platformtennisindex.org",
        redirectUri: getEnv() === "production" ? `${APTA_WEB}/redirect` : `${ngrokCallback}/redirect`,
        clientId: "79c0fak4hn4aei298ce3dr29lg",
    },
    apiSimulation: getEnv() === "production" ? APTA_API : "http://127.0.0.1:5000",
    apiStandard: getEnv() === "production" ? APTA_API : "http://127.0.0.1:3000",
};
