import * as React from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";
import Player from "./components/Players/Player";
import PlayerDirectory from "./components/Players/PlayerDirectory";
import RedirectPage from "./components/SSO/RediretPage";
import SignIn from "./components/SSO/SignIn";
import { AptaDataProvider } from "./hooks/AptaDataProvider";
import { AuthProvider } from "./hooks/AuthProvider";
import { RedirectProvider } from "./hooks/RedirectProvider";
import HealthPage from "./components/Health/Health";

export default function App(): JSX.Element {
    return (
        <RedirectProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<SignIn />} />
                    <Route path="/redirect" element={<RedirectPage />} />
                    <Route
                        path="players/*"
                        element={(
                            <AuthProvider>
                                <AptaDataProvider>
                                    <Routes>
                                        <Route path="" element={<PlayerDirectory />} />
                                        <Route path=":id" element={<Player />} />
                                    </Routes>
                                </AptaDataProvider>
                            </AuthProvider>
                        )}
                    />
                    <Route
                        path="health"
                        element={(
                            <AuthProvider>
                                <HealthPage />
                            </AuthProvider>
                        )}
                    />
                </Routes>
            </Router>
        </RedirectProvider>
    );
}
