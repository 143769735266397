export const CHART_COLORS = {
    red: "#ff6384",
    yellow: "#FFC107",
    green: "#004D40",
    pink: "#D81B60",
    white: "#FFFFFF",
    black: "#000000",
};
export const PTI_REFERENCE = "pti_reference";
export const TENNISCORES = "tenniscores";
export const PTI_V2 = "pti_v2"; // This is os_sets_mod in backend
// To configure a new rater, simple add the entry in this RATERS list.
// This assumes the sim backend is configured with the rating algorithm.
// Example:
// const YOUR_NEW_RATING_ALGO = "a_new_rating_algo";
// export const RATERS = [PTI_V2, PTI_REFERENCE];
// Also, add a color to RATERS_COLOR map
export const RATERS = [PTI_V2];
export const RATERS_OVERRIDE_PREFIXES = RATERS.map((rater) => `${rater}_`);
export const RATERS_COLORS: { [key: string]: string } = {
    [PTI_V2]: CHART_COLORS.pink,
};
export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
