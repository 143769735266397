import { DirectoryEntry } from "@apta/data/dist/directory";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useAptaDataContext } from "../../hooks/AptaDataProvider";

interface Props {
    defaultPlayers?: DirectoryEntry[];
    filterPlayers?: number[],
    handleAddPlayer?: (directoryEntry: DirectoryEntry | null) => void;
    handleAddPlayers?: (directory: DirectoryEntry[] | null) => void;
    multiple?: boolean;
}

export function getOptionLabel(directory: DirectoryEntry): string {
    if (!directory) {
        return "";
    }
    return `${directory.player_name} (${directory.player_id})`;
}

export default function PlayersAutoComplete({
    defaultPlayers = [],
    filterPlayers = [],
    multiple = false,
    handleAddPlayer = () => { },
    handleAddPlayers = () => { },
}: Props): JSX.Element {
    const { directory } = useAptaDataContext();
    const [open, setOpen] = React.useState(false);
    const loading = directory.length === 0;
    const [defaultValues, setDefaultValues] = React.useState<DirectoryEntry | DirectoryEntry[]>([]);

    React.useEffect(() => {
        if (directory.length > 0) {
            if (multiple) {
                setDefaultValues(defaultPlayers);
            } else {
                setDefaultValues(defaultPlayers[0]);
            }
        }
    }, [defaultPlayers, directory, multiple]);

    const options = directory.filter((directoryEntry) => !filterPlayers.includes(directoryEntry.player_id));

    return (
        <Autocomplete
            multiple={multiple}
            id="players-auto-complete"
            isOptionEqualToValue={(option, value) => option.player_id === value.player_id}
            filterOptions={createFilterOptions({ limit: 5 })}
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options}
            getOptionLabel={getOptionLabel}
            noOptionsText="No players"
            onChange={(_event, selection: DirectoryEntry[] | DirectoryEntry | null) => {
                if (selection instanceof Array) {
                    handleAddPlayers(selection);
                } else {
                    handleAddPlayer(selection);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="player"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            value={defaultValues}
            data-testid="PlayerAutoComplete_autoComplete"
        />
    );
}

PlayersAutoComplete.defaultProps = {
    defaultPlayers: [],
    filterPlayers: [],
    multiple: false,
    handleAddPlayer: () => { },
    handleAddPlayers: () => { },
};
