import React from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { postResource, xWwwFormUrlEncode } from "../../service/request.service";
import { TokenResponseAuthCode } from "../../types/auth";
import { useRedirectContext } from "../../hooks/RedirectProvider";

export default function RedirectPage() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const code = queryParameters.get("code") || "";
    const verifier = window.localStorage.getItem("verifier") || "";
    const details: { [key: string]: string } = {
        client_id: config.oauth2.clientId,
        redirect_uri: config.oauth2.redirectUri,
        grant_type: "authorization_code",
        code,
        code_verifier: verifier,
    };
    const formBody = xWwwFormUrlEncode(details);
    const data = formBody.join("&");
    const [errors, setErrors] = React.useState<string[]>([]);
    const { setInitialTokens } = useRedirectContext();

    React.useEffect(() => {
        postResource<TokenResponseAuthCode>(`${config.oauth2.authorizationServerHost}/oauth2/token`, data, "application/x-www-form-urlencoded")
            .then((response) => {
                window.localStorage.removeItem("verifier");
                window.localStorage.setItem("accessToken", response.access_token);
                window.localStorage.setItem("refreshToken", response.refresh_token);
                setInitialTokens({ accessToken: response.access_token, refreshToken: response.refresh_token });
                // Go to where user left off pre forced log out
                const player = JSON.parse(window.localStorage.getItem("player") || "{}") as {
                    newStartRatings: number, playerId: number, playerName: string
                };
                if (player && player.newStartRatings) {
                    navigate(`/players/${player.playerId}`, { state: { playerId: player.playerId, playerName: player.playerName } });
                } else {
                    // else go to home page
                    navigate("/players", { replace: true, state: { accessToken: response.access_token, refreshToken: response.refresh_token } });
                }
            })
            .catch((error: Error) => {
                setErrors((currentErrors) => {
                    currentErrors.push(error.message);
                    return [...currentErrors];
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        errors.length > 0 && (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <ul>
                    {errors.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </Alert>
        )
    );
}
