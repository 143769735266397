import { PlayerMatchHistorySim } from "../types/match-history";
import { getDateByUtc } from "./date";

export function findPlayerMatchSimHistory(simMatchHistory: PlayerMatchHistorySim[], playerId: number, rater: string) {
    return simMatchHistory.find((match) => match.player_id === playerId && match.rater === rater);
}

export function findMatchInSimHistoryByDate(simMatchHistory: PlayerMatchHistorySim[], playerId: number, rater: string, date: string) {
    // console.log(simMatchHistory, playerId, rater, date)
    const playerMatchHistory = findPlayerMatchSimHistory(simMatchHistory, playerId, rater);

    return playerMatchHistory?.match_history.find((match) => {
        const matchDate = getDateByUtc(match.date);
        if (matchDate.toISOString() === date) {
            return match;
        }

        return undefined;
    });
}
