import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Alert, AlertTitle, Card } from "@mui/material";
import Chip from "@mui/material/Chip/Chip";
import Grid from "@mui/system/Unstable_Grid";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { config } from "../../config";
import { RATERS, TENNISCORES } from "../../constants";
import { useAuthContext } from "../../hooks/AuthProvider";
import { LineData } from "../../types/line-data";
import { MatchTS, PlayerMatchHistorySim, PlayerMatchHistoryTS } from "../../types/match-history";
import { LocalStoragePlayers, NewStartRate } from "../../types/player";
import { isNotNullOrUndefined, transformManySimsToLineData, transformTsMatchesToLineData } from "../../utils/match";
import MainAppBar from "../AppBar/MainAppBar";
import MainBreadCrumb from "../BreadCrumb/MainBreadCrumb";
import LineChartInteractive from "../Chart/LineChartInteractive";
import PlayerDashBoard from "./PlayerDashboard";
import PlayerMatchHistory from "./PlayerMatchHistory";
import { Percentiles, PlayerRatingTable, findEndRating } from "./PlayerRatingTable";
import Footer from "../Footer/Footer";

interface PlayerLocationState {
    state: {
        playerId: number;
        playerName: string;
    }
}

export default function Player(): JSX.Element {
    const location = useLocation() as PlayerLocationState;

    // Auth
    const authContext = useAuthContext();
    const { handleGetRequest } = authContext;

    // Player info
    const { id } = useParams(); // a user may adjust the url path param with a different player id
    const [playerName, setPlayerName] = React.useState("");
    const playerId = parseInt(id || "", 10) || location.state?.playerId;

    // Player match data
    const [playerMatchHistoryTS, setPlayerMatchHistoryTS] = React.useState<MatchTS[]>();
    const [dataSet, setDataSet] = React.useState<LineData[]>([]);

    // Player stats
    const [startRatings, setStartRatings] = React.useState<{ [key: string]: number }>({});
    const [percentiles, setPercentiles] = React.useState<Percentiles>({
        endRatingPercentile: {},
        startRatingPercentile: {},
    });

    // Line Chart State
    const [initialNewStartRatings, setInitialNewStartRatings] = React.useState<NewStartRate[]>([]);

    const [expandAllAccordians, setExpandAllAccordians] = React.useState(true);
    const [errors, setErrors] = React.useState<string[]>([]);

    // Load initial data for player from API
    React.useEffect(() => {
        const raterReqs = RATERS.map((rater) => {
            const url = `${config.apiSimulation}/api/simulation/players/${playerId}/rating_history?rater=${rater}`;
            return handleGetRequest<PlayerMatchHistorySim>(url);
        });

        Promise.all([
            handleGetRequest<PlayerMatchHistoryTS>(`${config.apiStandard}/api/matches_unified_byplayer?algo=ptips&playerId=${playerId}`),
            ...raterReqs,
        ]).then(([tenniscoresResp, ...raterResps]) => {
            setPlayerName(tenniscoresResp.player_name);
            const tenniScoresLineData = transformTsMatchesToLineData(tenniscoresResp);
            const filteredTSMatches = tenniscoresResp.match_history.filter((match) => isNotNullOrUndefined(match.player_pti_end));
            // Rating algorithms line data dynamically generated
            const raterRespsLineData = transformManySimsToLineData(raterResps);

            // Rating algorithms start ratings dynamically generated
            const ratersStartRatings: { [key: string]: number } = {};
            const endRatingPercentilesResp: { [key: string]: number } = {};
            raterResps.forEach((raterResp) => {
                ratersStartRatings[raterResp.rater] = raterResp.match_history[0]?.rating_before || NaN;
                endRatingPercentilesResp[raterResp.rater] = raterResp.end_rating_percentile;
            });

            setPlayerMatchHistoryTS(filteredTSMatches);
            setDataSet((current) => [...raterRespsLineData, tenniScoresLineData, ...current]);
            setStartRatings((current) => ({
                ...current,
                ...ratersStartRatings,
                [TENNISCORES]: filteredTSMatches[0]?.player_pti,
            }));
            setPercentiles((current) => ({
                ...current,
                endRatingPercentile: {
                    ...current.endRatingPercentile,
                    ...endRatingPercentilesResp,
                },
            }));

            // Load previous saved session prior to forced logout (session expired)
            // This is not set in LineChartInteractive class because we want the default
            // data to load before the simulated data.
            const players = JSON.parse(window.localStorage.getItem("players") || "[]") as LocalStoragePlayers[];
            const newStartRatingsCache = players.find((player) => player.playerId === playerId)?.newStartRatings || [];
            setInitialNewStartRatings(newStartRatingsCache);
        }).catch((error: Error) => {
            setErrors((currentErrors) => {
                currentErrors.push(error.message);
                return [...currentErrors];
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerId]);

    return (
        <>
            <MainAppBar />
            <MainBreadCrumb path="player" />
            <Grid container spacing={1} sx={{ p: 1 }}>
                {
                    errors.length > 0 && (
                        <Alert severity="error" data-testid="Player_errorAlert">
                            <AlertTitle>Error</AlertTitle>
                            <ul>
                                {errors.map((item) => (
                                    <li key={item}>{item}</li>
                                ))}
                            </ul>
                        </Alert>
                    )
                }
                <Grid xs={12}>
                    <PlayerDashBoard
                        matchCount={playerMatchHistoryTS?.length || 0}
                        playerName={playerName}
                        endRating={findEndRating(dataSet.find((ds) => ds.label === TENNISCORES))}
                    />
                </Grid>
                <Grid xs={12}>
                    <Card variant="outlined">
                        <PlayerRatingTable dataSet={dataSet} startRatings={startRatings} percentiles={percentiles} />
                    </Card>
                </Grid>
                <Grid xs={12}>
                    <Card variant="outlined">
                        <LineChartInteractive
                            dataSet={dataSet}
                            playerId={playerId}
                            playerName={playerName}
                            initialNewStartRatings={initialNewStartRatings}
                            setDataSet={setDataSet}
                            setPercentiles={setPercentiles}
                            setStartRatings={setStartRatings}
                        />
                    </Card>
                </Grid>
                <Grid xs={12}>
                    <div style={{ margin: 15 }}>
                        {
                            expandAllAccordians
                                ? (
                                    <Chip
                                        icon={(
                                            <ExpandMoreIcon
                                                fontSize="large"
                                                onClick={() => setExpandAllAccordians((current) => !current)}
                                            />
                                        )}
                                        label="collapse all"
                                        size="medium"
                                    />
                                )
                                : (
                                    <Chip
                                        icon={(
                                            <KeyboardArrowRightIcon
                                                fontSize="large"
                                                onClick={() => setExpandAllAccordians((current) => !current)}
                                            />
                                        )}
                                        label="expand all"
                                        size="medium"
                                    />
                                )
                        }
                    </div>
                    <Card variant="outlined">
                        <PlayerMatchHistory
                            playerId={playerId}
                            expandAllAccordians={expandAllAccordians}
                            currentPlayerId={playerId}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}
