import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CryptoJS from "crypto-js";
import { config } from "../../config";

export function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {`© 2023 - ${new Date().getFullYear()} American Platform Tennis Association`}
        </Typography>
    );
}

const defaultTheme = createTheme(
    {
        palette: {
            primary: {
                main: "rgb(8, 72, 42)",
            },
            secondary: {
                main: "#941300",
            },
        },
    },
);

function base64URLEncode(data: CryptoJS.lib.WordArray) {
    return data.toString(CryptoJS.enc.Base64)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
}

export default function SignIn() {
    const { authorizationServerHost } = config.oauth2;
    const { clientId } = config.oauth2;
    const { redirectUri } = config.oauth2;
    const responseType = "code";
    // Proof Key for Code Exchange (PKCE: https://oauth.net/2/pkce/)
    const verifier = CryptoJS.lib.WordArray.random(128).toString();
    const codeChallenge = base64URLEncode(CryptoJS.SHA256(verifier));
    const codeChallengeMethod = "S256";

    const queryParams = [
        `client_id=${clientId}`,
        `code_challenge=${codeChallenge}`,
        `code_challenge_method=${codeChallengeMethod}`,
        `redirect_uri=${redirectUri}`,
        `response_type=${responseType}`,
    ];

    const onClick = () => {
        // Required when auth server redirects back to app
        window.localStorage.setItem("verifier", verifier);
    };

    return (
        <div style={{
            position: "fixed",
            padding: 0,
            margin: 0,

            top: 0,
            left: 0,

            width: "100%",
            height: "100%",
            backgroundImage: "linear-gradient(to top, rgba(218,210,153, 0.3), rgba(176,218,185, 0.3)",
        }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            padding: "5em",
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "white",
                            borderRadius: "8px",
                        }}
                    >
                        <Avatar sx={{
                            m: 1, bgcolor: "secondary.main", width: 56, height: 56,
                        }}
                        >
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography variant="h6">
                            Sign in to continue
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Button
                                onClick={onClick}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3, mb: 2, width: 190, textTransform: "none",
                                }}
                                size="large"
                                href={`${authorizationServerHost}/login?${queryParams.join("&")}`}
                            >
                                Sign in with SSO
                            </Button>
                        </Box>
                        <Copyright sx={{ mt: 8, mb: 4 }} />
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    );
}
