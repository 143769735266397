import { MatchUnified } from "@apta/data/dist/matches";

export function formatAccordianDate(entry: string): string {
    try {
        const date = (new Date(entry)).toLocaleString();
        const datetime = date.split(",");
        const timeWithPeriod = datetime[1].trim().split(" ");
        const timeParts = timeWithPeriod[0].split(":");
        return `${datetime[0]} at ${timeParts[0]}:${timeParts[1]} ${timeWithPeriod[1]}`;
    } catch (error) {
        return entry;
    }
}

export function formatTwoDecimal(number: number): number {
    return Math.round((number) * 100) / 100;
}

export function calculateWinExpectedHome(homeTeamStartRarting: number, awayTeamStartRating: number): number {
    const spread = homeTeamStartRarting - awayTeamStartRating;
    if (Math.abs(spread) >= 21) {
        return 100;
    }
    const exp = 1 / (1 + 10 ** (spread / 24));
    return formatTwoDecimal(exp * 100);
}

export function calculateWinExpectedAway(homeTeamStartRarting: number, awayTeamStartRating: number): number {
    return formatTwoDecimal(100 - calculateWinExpectedHome(homeTeamStartRarting, awayTeamStartRating));
}

export function getScale(scale: number): number | string {
    if (scale === -1) {
        return "skip";
    }
    return formatTwoDecimal(scale);
}

export function calculateTotalGamesPlayer(match: MatchUnified): number {
    return match.set_1_team_1_score + match.set_2_team_1_score + match.set_3_team_1_score
        + match.set_1_team_2_score + match.set_2_team_2_score + match.set_3_team_2_score;
}

export function calculateWinActualHome(match: MatchUnified): number {
    const totalGamesWon = match.set_1_team_1_score + match.set_2_team_1_score + match.set_3_team_1_score;
    const totalGames = calculateTotalGamesPlayer(match);
    let win = -1;

    if (match.winner === 1) {
        win = 1;
    } else {
        win = 0;
    }
    const actWin = ((1 / 3) * win) + ((2 / 3) * (totalGamesWon / totalGames));
    return formatTwoDecimal(100 * actWin);
}

export function calculateWinActualAway(match: MatchUnified): number {
    const totalGamesWon = match.set_1_team_2_score + match.set_2_team_2_score + match.set_3_team_2_score;
    const totalGames = calculateTotalGamesPlayer(match);
    let win = -1;

    if (match.winner === 2) {
        win = 1;
    } else {
        win = 0;
    }

    const actWin = ((1 / 3) * win) + ((2 / 3) * (totalGamesWon / totalGames));
    return formatTwoDecimal(100 * actWin);
}
