import { DirectoryEntry } from "@apta/data/dist/directory";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { PTI_V2, RATERS } from "../../constants";
import { useAptaDataContext } from "../../hooks/AptaDataProvider";
import { NewStartRate } from "../../types/player";
import { isFloat } from "../../utils/math";
import PlayersAutoComplete from "../Common/PlayersAutoComplete";

interface Props {
    defaultPlayerId: number;
    disable: boolean;
    handleSubmitParent: (value: NewStartRate, rapid: boolean) => void;
}

export default function OverrideRatingDialog({ disable, defaultPlayerId, handleSubmitParent }: Props) {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [helperText, sethelperText] = React.useState("");
    const [rater, setRater] = React.useState(PTI_V2);
    const [newStartRating, setNewStartRating] = React.useState("");
    const [playerId, setPlayerId] = React.useState(defaultPlayerId);
    const [playerName, setPlayerName] = React.useState("");
    const [includePlayers, setIncludePlayers] = React.useState<DirectoryEntry[]>([]);
    const [showAdvanced, setShowAdvanced] = React.useState(false);
    const [rapid, setRapid] = React.useState(true);

    const handleClickOpen = () => {
        setRater(PTI_V2);
        setNewStartRating("");
        setError(false);
        sethelperText("");
        setOpen(true);
        setShowAdvanced(false);
        setRapid(true);
        setIncludePlayers([]);
    };

    const handleClose = () => {
        setOpen(false);
        setPlayerId(defaultPlayerId);
    };

    const handleSubmit = () => {
        if (!isFloat(newStartRating)) {
            setError(true);
            sethelperText("integer required");
            return;
        }

        if (parseFloat(newStartRating) > 150 || parseFloat(newStartRating) < -150) {
            setError(true);
            sethelperText("-150 <= new start rating <= 150");
            return;
        }

        const playerIds = includePlayers.map((player) => player.player_id);

        handleClose();
        handleSubmitParent(
            {
                playerId,
                playerName,
                rating: parseFloat(newStartRating),
                rater,
                include: playerIds,
                rapid,
            },
            rapid,
        );
        setPlayerId(defaultPlayerId);
    };

    const handleStartRateChange = (event: { target: { value: string; }; }) => {
        setNewStartRating(event.target.value);
    };

    const handleRapidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRapid(event.target.checked);
    };

    const { directory } = useAptaDataContext();
    const directoryEntry = directory.find((entry) => entry.player_id === playerId);

    return (
        <div>
            <Button
                variant="outlined"
                disabled={disable}
                onClick={handleClickOpen}
                sx={{ textTransform: "none", minWidth: 150 }}
                data-testid="Player_newRatingDialog"
            >
                Start Rating
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" scroll="body">
                <DialogTitle>Simulate Start Rating</DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={2}
                        sx={{ mt: 1 }}
                        data-testid="OverrideRatingDialog_box"
                    >

                        <PlayersAutoComplete
                            handleAddPlayer={(entry) => {
                                if (entry) {
                                    setPlayerId(entry.player_id);
                                    setPlayerName(entry.player_name);
                                }
                            }}
                            defaultPlayers={directoryEntry ? [directoryEntry] : []}
                            multiple={false}
                        />
                        <FormControl variant="standard">
                            <InputLabel id="whatif-simple-select-label" sx={{ fontSize: "1.2em" }}>rater</InputLabel>
                            <Select
                                labelId="whatif-simple-select-label"
                                id="whatif-simple-select"
                                defaultValue={PTI_V2}
                                value={rater}
                                label="Rater"
                                margin="none"
                                onChange={(event: SelectChangeEvent) => {
                                    setRater(event.target.value);
                                }}
                                sx={{ width: "100%", fontSize: "1.2em" }}
                            >
                                {
                                    RATERS.map((rater_name) => (
                                        <MenuItem key={rater_name} value={rater_name} sx={{ fontSize: "1.2em" }}>
                                            {rater_name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            error={error}
                            autoFocus
                            id="new_start_rating"
                            label="new start rating"
                            fullWidth
                            variant="standard"
                            onChange={handleStartRateChange}
                            helperText={helperText}
                            value={newStartRating}
                            inputProps={{
                                "data-testid": "OverrideRatingDialog_newStartRating",
                            }}
                        />
                        <Stack direction="row">
                            <Typography variant="body1" color="grey">Advanced Options</Typography>
                            {
                                showAdvanced
                                    ? <ArrowDropDownIcon onClick={() => setShowAdvanced(!showAdvanced)} />
                                    : <ArrowRightIcon onClick={() => setShowAdvanced(!showAdvanced)} />
                            }
                        </Stack>
                        {/* Container for Advanced Options */}
                        <Box sx={{ display: showAdvanced ? "" : "none" }}>
                            <Typography variant="body2" sx={{ m: 0.5 }}>Display other player(s) from whatif scenario</Typography>
                            <PlayersAutoComplete
                                handleAddPlayers={(entry) => {
                                    if (entry) {
                                        setIncludePlayers(entry);
                                    }
                                }}
                                defaultPlayers={includePlayers}
                                filterPlayers={directoryEntry ? [directoryEntry.player_id] : []}
                                multiple
                            />
                            <Divider sx={{ mt: 2, mb: 1 }} />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={rapid} onChange={handleRapidChange} />} label="rapid" />
                            </FormGroup>
                            <Alert severity="info">
                                Unchecking rapid will increase processing time to about 30 seconds or more.
                                You can apply this to any of the simulation runs.
                            </Alert>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit} data-testid="OverrideRatingDialog_submit">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
