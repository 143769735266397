import format from "date-fns-tz/fp/format";
import { DateTime } from "luxon";

export const usDateFormat = format("MM/dd/yyyy hh:mm:ss aa (z)");
export const usDateFormatNoSeconds = format("MM/dd/yyyy hh:mm aa (z)");

export function getDateFormatByUtc(date: string): string {
    let dateWithZeroOffset: string;

    if (date.endsWith("Z")) {
        dateWithZeroOffset = date;
    } else {
        dateWithZeroOffset = `${date}Z`;
    }

    return usDateFormatNoSeconds(new Date(dateWithZeroOffset));
}

export function getDateByUtc(date: string): Date {
    let dateWithZeroOffset: string;

    if (date.endsWith("Z")) {
        dateWithZeroOffset = date;
    } else {
        dateWithZeroOffset = `${date}Z`;
    }

    return new Date(dateWithZeroOffset);
}

// Check if daylight saving time is currently in effect
export function isDaylightSavingTime() {
    const today = DateTime.local();
    return today.isInDST;
}
