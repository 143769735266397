// src/componetns/Footer.tsx

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Copyright } from "../SSO/SignIn";

export default function Footer(): JSX.Element {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                height: "100%",
                minHeight: "25vh",
                // backgroundColor: "#004D40",
                p: 2,
            }}
        >
            <Divider sx={{ m: 3 }} variant="middle" />
            <Container maxWidth="lg">
                <Grid container direction="column" alignItems="center" color="black">
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                textDecoration: "underline",
                                color: "blue",
                            }}
                            onClick={() => navigate("/health")}
                        >
                            Health
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Copyright sx={{ m: 1, color: "inherit" }} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
