import jwt_decode from "jwt-decode";
import { TokenPayload } from "../types/auth";

export function expiredToken(base64EncodedToken: string): boolean {
    if (!base64EncodedToken) {
        throw Error("No auth context");
    }

    const token: TokenPayload = jwt_decode(base64EncodedToken);

    if (Date.now() >= (token.exp * 1000)) {
        return true;
    }

    return false;
}

export function getTokenPayload(base64EncodedToken: string): TokenPayload {
    return jwt_decode(base64EncodedToken);
}
