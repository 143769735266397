import React, {
    Dispatch, SetStateAction, createContext, useContext,
} from "react";
import { TokenContext } from "../types/auth";
import { expiredToken } from "../utils/auth";

const DEFAULT_INITIAL_TOKENS: TokenContext = {
    accessToken: "",
    refreshToken: "",
};

export interface RedirectAuthContext {
    initialTokens: TokenContext;
    setInitialTokens: Dispatch<SetStateAction<{ accessToken: string; refreshToken: string; }>>;
    clearInitialTokens: () => void
}

const RedirectContext = createContext<RedirectAuthContext>({
    initialTokens: {
        accessToken: "",
        refreshToken: "",
    },
    setInitialTokens: () => { },
    clearInitialTokens: () => { },
});

export function useRedirectContext(): RedirectAuthContext {
    return useContext(RedirectContext);
}

/**
 * This context captures the auth context when app loads from an SSO redirect.
 * The important item here is an auth code that can only be used once in an
 * exchange for tokens.
 *
 * This provider also serves as the initial loading logic if the app is
 * refreshed.
 */
export function RedirectProvider({ children }: { children: React.ReactElement }) {
    let defaultInitialTokens = DEFAULT_INITIAL_TOKENS;

    // Support hyper links to new tabs
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamAccessToken = queryParams.get("accessToken");
    const queryParamRefreshToken = queryParams.get("refreshToken");

    // Support refresh in browser
    const localStorageAccessToken = window.localStorage.getItem("accessToken");
    const localStorageRefreshToken = window.localStorage.getItem("refreshToken");

    if (localStorageAccessToken && localStorageRefreshToken) {
        defaultInitialTokens = {
            accessToken: localStorageAccessToken,
            refreshToken: localStorageRefreshToken,
        };
    } else if (queryParamAccessToken && queryParamRefreshToken && !expiredToken(queryParamAccessToken)) {
        defaultInitialTokens = {
            accessToken: queryParamAccessToken,
            refreshToken: queryParamRefreshToken,
        };
    }

    const [initialTokens, setInitialTokens] = React.useState(defaultInitialTokens);

    function clearInitialTokens() {
        setInitialTokens(DEFAULT_INITIAL_TOKENS);
    }

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <RedirectContext.Provider value={{ initialTokens, setInitialTokens, clearInitialTokens }}>
            {children}
        </RedirectContext.Provider>
    );
}
