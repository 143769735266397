import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import {
    Breadcrumbs, Link, LinkProps, Typography,
} from "@mui/material";
import * as React from "react";
import {
    Link as RouterLink,
} from "react-router-dom";

interface Props {
    path: string
}

interface LinkRouterProps extends LinkProps {
    to: string;
}

function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink} />;
}

export default function MainBreadCrumb({ path }: Props): JSX.Element {
    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mt: 3, mb: 3, ml: 0.5 }}>
            <LinkRouter
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                to="/players"

            >
                <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Directory
            </LinkRouter>
            {
                path === "player" && (
                    <span
                        style={{ display: "flex", alignItems: "center" }}
                        color="inherit"
                    >
                        <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                            color="text.primary"
                        >
                            <SportsTennisIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Player
                        </Typography>
                    </span>
                )
            }
        </Breadcrumbs>
    );
}
