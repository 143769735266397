import { MatchUnified } from "@apta/data/dist/matches";
import { Division } from "@apta/data/dist/divisions";
import { TournamentLabel } from "@apta/data/dist/tournament";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
    Alert,
    AlertTitle,
    Divider,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { config } from "../../config";
import { RATERS } from "../../constants";
import { useAptaDataContext } from "../../hooks/AptaDataProvider";
import { useAuthContext } from "../../hooks/AuthProvider";
import { PlayerMatchHistorySim } from "../../types/match-history";
import { getDateFormatByUtc } from "../../utils/date";
import { findMatchInSimHistoryByDate } from "../../utils/search";
import {
    calculateWinActualAway,
    calculateWinActualHome,
    calculateWinExpectedAway,
    calculateWinExpectedHome,
    formatTwoDecimal,
    getScale,
} from "../../utils/stats";
import { StyledTableCell, StyledTableRow } from "./PlayerRatingTable";
import { AWAY, HOME, getWinner } from "../../utils/match";

const EMPTY_CELL = "n/a";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function getTeamTableRow(
    advancedStats: boolean,
    currentPlayerId: number,
    date: string,
    expandedAccordion: boolean,
    matchRatings: PlayerMatchHistorySim[],
    matchSide: string,
    matchWinner: boolean,
    player1Id: number,
    player2Id: number,
    player1Name: string,
    player2Name: string,
    player1RatingStart: number,
    player2RatingStart: number,
    player1RatingEnd: number,
    player2RatingEnd: number,
    set1TeamScore: number,
    set2TeamScore: number,
    set3TeamScore: number,
    navigate: NavigateFunction,
    accessToken: string,
    refreshToken: string,
) {
    return (
        <>
            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                {matchSide}
                {" "}
                {matchWinner && <CheckCircleOutlineIcon sx={{ color: "green" }} />}
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                {
                    currentPlayerId !== player1Id ? (
                        <a
                            target="blank"
                            href={`/players/${player1Id}?accessToken=${accessToken}&refreshToken=${refreshToken}`}
                        >
                            {player1Name}
                        </a>
                    ) : player1Name
                }
                <br />
                {
                    currentPlayerId !== player2Id ? (
                        <a
                            target="blank"
                            href={`/players/${player2Id}?accessToken=${accessToken}&refreshToken=${refreshToken}`}
                        >
                            {player2Name}
                        </a>
                    ) : player2Name
                }
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                {`${set1TeamScore}-${set2TeamScore}-${set3TeamScore}`}
            </StyledTableCell>
            {
                advancedStats && matchRatings.length > 0 && (
                    <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>
                        <Stack spacing={1.5}>
                            <span>tenniscores</span>
                            {
                                RATERS.map((rater) => <span key={rater}>{rater}</span>)
                            }
                        </Stack>
                    </StyledTableCell>
                )
            }
            <StyledTableCell align="left" style={{ fontSize: "1em" }}>

                {formatTwoDecimal(player1RatingStart) || EMPTY_CELL}
                <br />
                {formatTwoDecimal(player2RatingStart) || EMPTY_CELL}
                {
                    advancedStats && matchRatings.length > 0 && expandedAccordion && (
                        <>
                            {
                                RATERS.map((rater) => (
                                    <div key={rater}>
                                        <Divider />
                                        {formatTwoDecimal(findMatchInSimHistoryByDate(
                                            matchRatings,
                                            player1Id,
                                            rater,
                                            date,
                                        )?.rating_before || NaN) || EMPTY_CELL}
                                        <br />
                                        {formatTwoDecimal(findMatchInSimHistoryByDate(
                                            matchRatings,
                                            player2Id,
                                            rater,
                                            date,
                                        )?.rating_before || NaN) || EMPTY_CELL}
                                    </div>
                                ))
                            }
                        </>
                    )
                }
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                {formatTwoDecimal(player1RatingEnd) || EMPTY_CELL}
                <br />
                {formatTwoDecimal(player2RatingEnd) || EMPTY_CELL}
                {
                    advancedStats && matchRatings.length > 0 && expandedAccordion && (
                        <>
                            {
                                RATERS.map((rater) => (
                                    <div key={rater}>
                                        <Divider />
                                        {formatTwoDecimal(findMatchInSimHistoryByDate(
                                            matchRatings,
                                            player1Id,
                                            rater,
                                            date,
                                        )?.rating_after || NaN) || EMPTY_CELL}
                                        <br />
                                        {formatTwoDecimal(findMatchInSimHistoryByDate(
                                            matchRatings,
                                            player2Id,
                                            rater,
                                            date,
                                        )?.rating_after || NaN) || EMPTY_CELL}
                                    </div>
                                ))
                            }
                        </>
                    )
                }
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                <Stack spacing={1.5}>
                    <span>{formatTwoDecimal((formatTwoDecimal(player1RatingStart) + formatTwoDecimal(player2RatingStart))) || EMPTY_CELL}</span>
                    {
                        advancedStats && matchRatings.length > 0 && expandedAccordion && (
                            <>
                                {
                                    RATERS.map((rater) => (
                                        <span key={rater}>
                                            {formatTwoDecimal(formatTwoDecimal(findMatchInSimHistoryByDate(
                                                matchRatings,
                                                player1Id,
                                                rater,
                                                date,
                                            )?.rating_before || NaN)
                                                + formatTwoDecimal(findMatchInSimHistoryByDate(
                                                    matchRatings,
                                                    player2Id,
                                                    rater,
                                                    date,
                                                )?.rating_before || NaN)) || EMPTY_CELL}
                                        </span>
                                    ))
                                }
                            </>
                        )
                    }
                </Stack>
            </StyledTableCell>
            <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                <Stack spacing={1.5}>
                    <span>{formatTwoDecimal((formatTwoDecimal(player1RatingEnd) + formatTwoDecimal(player2RatingEnd))) || EMPTY_CELL}</span>
                    {
                        advancedStats && matchRatings.length > 0 && expandedAccordion && (
                            <>
                                {
                                    RATERS.map((rater) => (
                                        <span key={rater}>
                                            {formatTwoDecimal(formatTwoDecimal(findMatchInSimHistoryByDate(
                                                matchRatings,
                                                player1Id,
                                                rater,
                                                date,
                                            )?.rating_after || NaN)
                                                + formatTwoDecimal(findMatchInSimHistoryByDate(
                                                    matchRatings,
                                                    player2Id,
                                                    rater,
                                                    date,
                                                )?.rating_after || NaN)) || EMPTY_CELL}
                                        </span>
                                    ))
                                }
                            </>
                        )
                    }
                </Stack>
            </StyledTableCell>
        </>
    );
}

/**
 * The match should be either a divisional match or a tournament match.
 */
function formatMatchMetaData(
    match: MatchUnified,
    divisions: Division[],
    tournamentLabels: TournamentLabel[],
): string {
    const tournamentLabel = tournamentLabels.find((tl) => tl.match_schedule_id === match.schedule_id);
    const division = divisions.find((d) => {
        if (d.id === match.division_id) {
            return true;
        }

        const sameLeague = d.league_id === match.league_id;
        const sameSeason = d.season_id === match.season_id;
        const hasTeam = d.team_id === match.team_1_id || d.team_id === match.team_2_id;
        return sameLeague && sameSeason && hasTeam;
    });
    const divisionName = division?.name || "n/a";

    if (tournamentLabel) {
        return `Tournament - ${tournamentLabel.tournament_level_name}, Line ${match.line_number}`;
    }

    if (match.team_2 && match.team_1) {
        return `Division ${divisionName}, ${match.team_2} vs ${match.team_1}, Line ${match.line_number}`;
    }

    return `Division ${divisionName}, Line ${match.line_number}`;
}

interface Props {
    playerId: number;
    expandAllAccordians: boolean;
    currentPlayerId: number;
}

const idsOpenAccordian: { [key: string]: Array<string> } = {};

export default function PlayerMatchHistory({
    playerId, expandAllAccordians, currentPlayerId,
}: Props) {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [playerId]);

    const authContext = useAuthContext();
    const { accessToken, refreshToken, handleGetRequest } = authContext;
    const navigate = useNavigate();
    const [playerMatchHistoryTS, setPlayerMatchHistoryTS] = React.useState<MatchUnified[]>([]);
    const [expandedAccordions, setExpandedAccordions] = React.useState<Array<string>>([]);
    const [matchRatings, setMatchRatings] = React.useState<PlayerMatchHistorySim[]>([]);
    const [advancedStatsToggles, setAdvancedStatsToggles] = React.useState<Set<string>>(new Set());
    const [errors, setErrors] = React.useState<string[]>([]);
    const {
        divisions, seasons, leagues, tournamentLabels,
    } = useAptaDataContext();

    // Clean up cached data when going from expand all then close all switch
    React.useEffect(() => {
        if (!expandAllAccordians) {
            setAdvancedStatsToggles(new Set());
            setMatchRatings([]);
        }
    }, [expandAllAccordians]);

    React.useEffect(() => {
        handleGetRequest<MatchUnified[]>(`${config.apiStandard}/api/matches_unified?algo=ptips&playerId=${playerId}`)
            .then((response) => {
                setPlayerMatchHistoryTS(response);
            }).catch((error: Error) => {
                setErrors((currentErrors) => {
                    currentErrors.push(error.message);
                    return [...currentErrors];
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerId, handleGetRequest]);

    const playerMatchHistoryTSReversed = playerMatchHistoryTS.sort((match1, match2) => {
        const date1 = new Date(match1.date);
        const date2 = new Date(match2.date);
        if (date1 <= date2) {
            return 1;
        }
        return -1;
    });

    const updateMatchRatings = (match: MatchUnified, index: string) => {
        const ids = [
            match.player_1_home_id,
            match.player_2_home_id,
            match.player_1_away_id,
            match.player_2_away_id,
        ];

        if (advancedStatsToggles.has(index)) {
            /**
             * Remove the cached players if accordian advanceded stats toggle is closed with this criteria:
             * remove the player if they are part of the switch being closed, see ids.includes(match.player_id)
             * AND remove the player if they are not displayed in an open accordian elsewhere
             */
            const filtered = matchRatings.filter((simMatch) => !(ids.includes(simMatch.player_id)
                && idsOpenAccordian[simMatch.player_id.toString()]?.length === 0
            ));
            setMatchRatings([...filtered]);
            setAdvancedStatsToggles((current) => {
                current.delete(index);
                return new Set(current);
            });
        } else { // advanceded stats toggle is turned on (open accordian)
            // If a player id is not in cache, send player id as part of newIds in API request
            const newIds = ids.filter((id) => !matchRatings.find((simMatch) => simMatch.player_id === id));
            /**
             * idsOpenAccordian keeps track of all open accordians for players
             * player John for example can be open in multiple accordians
             * keeping track is important because later when we remove a player from
             * cache, when accordian X is closed for example, we want to make sure
             * they are not open in another accordian Y.
             * player cache is cleared when an accordian is closed, but this is when we want to ensure
             * another accodian is not open with similar data.
             */
            ids.forEach((id) => {
                if (Object.keys(idsOpenAccordian).includes(id.toString())) {
                    idsOpenAccordian[id]?.push(index);
                } else {
                    idsOpenAccordian[id] = [index];
                }
            });

            if (newIds.length > 0) {
                const queryParams = `player_ids=${newIds.toString()}&raters=${RATERS.toString()}`;
                const url = `${config.apiSimulation}/api/simulation/matches/rating_history?${queryParams}`;

                handleGetRequest<PlayerMatchHistorySim[]>(url)
                    .then((response) => {
                        setMatchRatings((current) => {
                            if (current) {
                                return [...current, ...response];
                            }
                            return response;
                        });

                        setAdvancedStatsToggles((current) => {
                            const updatedToggles = new Set(current);
                            updatedToggles.add(index);
                            return updatedToggles;
                        });
                    }).catch((error: Error) => {
                        setErrors((currentErrors) => {
                            currentErrors.push(error.message);
                            return [...currentErrors];
                        });
                    });
            }

            // This can happen when an accordian contains a match of 4 players, a user tries to turn
            // on advanced stats, and another accordian, which is already open, contains the same
            //  exact players. In this case no API call is needed, but do open the accordian.
            if (newIds.length === 0) {
                setAdvancedStatsToggles((current) => {
                    const updatedToggles = new Set(current);
                    updatedToggles.add(index);
                    return updatedToggles;
                });
            }
        }
    };

    const handleAccordianChange = (match: MatchUnified, index: string) => {
        const ids = [
            match.player_1_home_id,
            match.player_2_home_id,
            match.player_1_away_id,
            match.player_2_away_id,
        ];

        // Accordian is now closing
        if (expandedAccordions.includes(index) || expandAllAccordians) {
            setExpandedAccordions(expandedAccordions.filter((number) => number !== index));

            // Remove current index entry from the open accordian indexs for the player
            ids.forEach((id) => {
                idsOpenAccordian[id] = idsOpenAccordian[id]?.filter((i) => i !== index);
            });

            /**
             * Remove the cached players if accordian is closed with this criteria:
             * remove the player if they are part of the accordian being closed, see ids.includes(match.player_id)
             * AND remove the player if they are not displayed in an open accordian elsewhere
             */
            const filtered = matchRatings
                .filter((simMatch) => !(ids.includes(simMatch.player_id) && idsOpenAccordian[simMatch.player_id.toString()]?.length === 0));

            setMatchRatings([...filtered]);
            setAdvancedStatsToggles((current) => {
                current.delete(index);
                return new Set(current);
            });
        } else { // Accordian is now opening
            setExpandedAccordions([...expandedAccordions, index]);
        }
    };

    return (
        <div data-testid="PlayerMatchHistory_accordian">
            {
                errors.length > 0 && (
                    <Alert severity="error" data-testid="PlayerDirectory_errorAlert">
                        <AlertTitle>Error</AlertTitle>
                        <ul>
                            {errors.map((item) => (
                                <li key={item}>{item}</li>
                            ))}
                        </ul>
                    </Alert>
                )
            }
            {
                playerMatchHistoryTSReversed.map((match, i) => {
                    const index = `${i}_${match.date}`;
                    const openAccordian = expandedAccordions.includes(index) || expandAllAccordians;
                    const season = seasons.find((s) => s.id === match.season_id);
                    const league = leagues.find((l) => l.id === match.league_id);
                    return (
                        <Accordion
                            key={index}
                            sx={{ padding: 1 }}
                            expanded={openAccordian}
                            onChange={() => handleAccordianChange(match, index)}
                        >
                            <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                sx={{ backgroundColor: "white", padding: 1, userSelect: "text" }}
                                data-testid={`PlayerMatchHistory_accordianSummary_${i}`}
                            >
                                <div style={{ marginLeft: 5 }}>
                                    <Typography variant="h6" sx={{ m: 1 }}>
                                        {`${season?.name} | ${league?.name}`}
                                    </Typography>
                                    <Typography variant="body1" sx={{ m: 1 }}>
                                        {getDateFormatByUtc(match.date)}
                                    </Typography>
                                    <Typography variant="body1" sx={{ m: 1 }}>
                                        {formatMatchMetaData(match, divisions, tournamentLabels)}
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails data-testid={`PlayerMatchHistory_accordianDetails_${i}`}>
                                <div style={{ marginBottom: 1 }}>
                                    <Switch
                                        id={index}
                                        onClick={() => {
                                            updateMatchRatings(match, index);
                                        }}
                                        checked={advancedStatsToggles.has(index)}
                                        data-testid={`PlayerMatchHistory_switch_${i}`}
                                    />
                                    {" "}
                                    advanced stats
                                </div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }} />
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Team</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Match</StyledTableCell>
                                                {
                                                    advancedStatsToggles.has(index) && matchRatings.length > 0 && (
                                                        <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Rater</StyledTableCell>
                                                    )
                                                }
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Start Rating</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>End Rating</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Team Start</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Team End</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Exp Win</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Act Win</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Scale</StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1.1em" }}>Conf</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* HOME TEAM ROW */}
                                            <StyledTableRow>
                                                {
                                                    getTeamTableRow(
                                                        advancedStatsToggles.has(index),
                                                        currentPlayerId,
                                                        match.date,
                                                        openAccordian,
                                                        matchRatings,
                                                        "Home",
                                                        getWinner(match.winner) === HOME,
                                                        match.player_1_home_id,
                                                        match.player_2_home_id,
                                                        match.player_1_home_name,
                                                        match.player_2_home_name,
                                                        match.player_1_home_elo_start,
                                                        match.player_2_home_elo_start,
                                                        match.player_1_home_elo_end,
                                                        match.player_2_home_elo_end,
                                                        match.set_1_team_1_score,
                                                        match.set_2_team_1_score,
                                                        match.set_3_team_1_score,
                                                        navigate,
                                                        accessToken,
                                                        refreshToken,
                                                    )
                                                }
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    <Stack spacing={1.5}>
                                                        <span>
                                                            {
                                                                calculateWinExpectedHome(
                                                                    match.player_1_home_elo_start + match.player_2_home_elo_start,
                                                                    match.player_1_away_elo_start + match.player_2_away_elo_start,
                                                                )
                                                            }
                                                            %
                                                        </span>
                                                        {
                                                            advancedStatsToggles.has(index) && matchRatings.length > 0 && openAccordian
                                                            && RATERS.map((rater) => (
                                                                <span key={rater}>
                                                                    {calculateWinExpectedHome(
                                                                        (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_1_home_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN)
                                                                        + (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_2_home_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN),
                                                                        (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_1_away_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN)
                                                                        + (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_2_away_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN),
                                                                    ) || 0}
                                                                    %
                                                                </span>
                                                            ))
                                                        }
                                                    </Stack>
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    {calculateWinActualHome(match)}
                                                    %
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    {getScale(match.player_1_home_elo_scale) || EMPTY_CELL}
                                                    <br />
                                                    {getScale(match.player_2_home_elo_scale) || EMPTY_CELL}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    {match.player_1_home_elo_confidence || 0}
                                                    %
                                                    <br />
                                                    {match.player_2_home_elo_confidence || 0}
                                                    %
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            {/* AWAY TEAM ROW */}
                                            <StyledTableRow>
                                                {
                                                    getTeamTableRow(
                                                        advancedStatsToggles.has(index),
                                                        currentPlayerId,
                                                        match.date,
                                                        openAccordian,
                                                        matchRatings,
                                                        "Away",
                                                        getWinner(match.winner) === AWAY,
                                                        match.player_1_away_id,
                                                        match.player_2_away_id,
                                                        match.player_1_away_name,
                                                        match.player_2_away_name,
                                                        match.player_1_away_elo_start,
                                                        match.player_2_away_elo_start,
                                                        match.player_1_away_elo_end,
                                                        match.player_2_away_elo_end,
                                                        match.set_1_team_2_score,
                                                        match.set_2_team_2_score,
                                                        match.set_3_team_2_score,
                                                        navigate,
                                                        accessToken,
                                                        refreshToken,
                                                    )
                                                }
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    <Stack spacing={1.5}>
                                                        <span>
                                                            {
                                                                calculateWinExpectedAway(
                                                                    match.player_1_home_elo_start + match.player_2_home_elo_start,
                                                                    match.player_1_away_elo_start + match.player_2_away_elo_start,
                                                                )
                                                            }
                                                            %
                                                        </span>
                                                        {
                                                            advancedStatsToggles.has(index) && matchRatings.length > 0 && openAccordian
                                                            && RATERS.map((rater) => (
                                                                <span key={rater}>
                                                                    {calculateWinExpectedAway(
                                                                        (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_1_home_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN)
                                                                        + (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_2_home_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN),
                                                                        (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_1_away_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN)
                                                                        + (findMatchInSimHistoryByDate(
                                                                            matchRatings,
                                                                            match.player_2_away_id,
                                                                            rater,
                                                                            match.date,
                                                                        )?.rating_before || NaN),
                                                                    ) || 0}
                                                                    %
                                                                </span>
                                                            ))
                                                        }
                                                    </Stack>
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    {calculateWinActualAway(match)}
                                                    %
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    {getScale(match.player_1_away_elo_scale) || EMPTY_CELL}
                                                    <br />
                                                    {getScale(match.player_2_away_elo_scale) || EMPTY_CELL}
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ fontSize: "1em" }}>
                                                    {match.player_1_away_elo_confidence || 0}
                                                    %
                                                    <br />
                                                    {match.player_2_away_elo_confidence || 0}
                                                    %
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            }
        </div>
    );
}
