import { Alert } from "@mui/material";
import Box from "@mui/system/Box";
import * as React from "react";
import { isDaylightSavingTime } from "../../utils/date";

export default function MaintenanceBanner(): JSX.Element | null {
    const [timeWindow, setTimeWindow] = React.useState(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
            const currentDate = new Date();
            const currentHour = currentDate.getUTCHours();
            const currentMinute = currentDate.getUTCMinutes();
            const maintenanceHourUtc = isDaylightSavingTime() ? 12 : 13;
            setTimeWindow(currentHour === maintenanceHourUtc && currentMinute >= 0 && currentMinute <= 59);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        timeWindow
            ? (
                <Box>
                    <Alert
                        severity="warning"
                        sx={{ m: 1, p: 5 }}
                    >
                        <span>Maintenance in progress due to data updates.</span>
                        <br />
                        <span>Please expect some downtime between 8:30 AM - 9:00 AM (EDT).</span>
                        <br />
                        <span>Sorry for the inconvenience.</span>
                    </Alert>
                </Box>
            )
            : null
    );
}
