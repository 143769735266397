import React from "react";
import { useNavigate } from "react-router-dom";
import { getResource, postResource } from "../service/request.service";
import { AuthContext } from "../types/auth";
import { expiredToken } from "../utils/auth";
import { useRedirectContext } from "./RedirectProvider";

interface Props {
    children: React.ReactNode;
}

function defaultHandleGetRequest<T>() {
    return Promise.resolve({} as T);
}
function defaultHandlePostRequest<T>() {
    return Promise.resolve({} as T);
}

const DEFAULT_CONTEXT = {
    accessToken: "",
    refreshToken: "",
    getAccessToken: () => Promise.resolve(""),
    destroySession: () => {},
    handlePostRequest: defaultHandleGetRequest,
    handleGetRequest: defaultHandlePostRequest,
};

export const authContext = React.createContext<AuthContext>(DEFAULT_CONTEXT);

export function AuthProvider({ children }: Props): JSX.Element {
    const navigate = useNavigate();
    const { initialTokens, clearInitialTokens } = useRedirectContext();
    const [tokens, setTokens] = React.useState<AuthContext>({
        accessToken: initialTokens.accessToken,
        refreshToken: initialTokens.refreshToken,
        getAccessToken: DEFAULT_CONTEXT.getAccessToken,
        destroySession: DEFAULT_CONTEXT.destroySession,
        handleGetRequest: DEFAULT_CONTEXT.handleGetRequest,
        handlePostRequest: DEFAULT_CONTEXT.handlePostRequest,
    });

    const destroySession = React.useCallback(
        () => () => {
            clearInitialTokens();
            setTokens(DEFAULT_CONTEXT);
            window.localStorage.clear();
        },
        [clearInitialTokens],
    );

    // This doesn't work as a callback (too many rerenders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getAccessToken = async () => {
        if (tokens.accessToken && expiredToken(tokens.accessToken)) {
            return null;
        }

        return Promise.resolve(tokens.accessToken);
    };

    async function handlePostRequest<T>(
        url: string,
        data: unknown,
        contentType: string,
    ): Promise<T> {
        const token = await getAccessToken();

        if (!token) {
            clearInitialTokens();
            setTokens(DEFAULT_CONTEXT);
            navigate("/", { replace: true });
            return Promise.resolve({} as T);
        }

        return await postResource<T>(url, data, contentType, token);
    }

    async function handleGetRequest<T>(
        url: string,
        headers: { [key: string]: string } = {
            "Content-Type": "application/json",
        },
    ): Promise<T> {
        const token = await getAccessToken();

        if (!token) {
            clearInitialTokens();
            setTokens(DEFAULT_CONTEXT);
            navigate("/", { replace: true });
            return Promise.resolve({} as T);
        }

        return await getResource<T>(url, token, headers);
    }

    // getAccessToken will cause too many renrenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedHandlePostRequest = React.useCallback(handlePostRequest, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedHandleGetRequest = React.useCallback(handleGetRequest, []);

    const providerValue = React.useMemo(
        () => ({
            ...tokens,
            getAccessToken,
            destroySession,
            handleGetRequest: memoizedHandleGetRequest,
            handlePostRequest: memoizedHandlePostRequest,
        }),
        [
            destroySession,
            getAccessToken,
            memoizedHandlePostRequest,
            memoizedHandleGetRequest,
            tokens,
        ],
    );

    return (
        <authContext.Provider value={providerValue}>
            {children}
        </authContext.Provider>
    );
}

export function useAuthContext(): AuthContext {
    return React.useContext(authContext);
}
