export function containsOnlyNumbers(str: string) {
    return /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(str);
}

export function isFloat(str: string): boolean {
    const number = parseFloat(str);

    if (!number) {
        return false;
    }

    // This is helpful because parseInt("283xyz", 10) will return 283 as a number,
    // but let us enfore strict numbers.
    if (!containsOnlyNumbers(str)) {
        return false;
    }

    return true;
}
